import { COLOR } from '../theme';

export const Logo = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill={COLOR.PRIMARY}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.5217 10.7197H35.1495L36.4052 13.2311V26.4187L35.1495 28.931H34.5217V24.5351L35.1495 23.9073V18.2557L34.5217 17.627V10.7197Z"
    />
    <path
      d="M1.88356 28.931H1.25571L0 26.4187V13.2311L1.25571 10.7197H1.88356V15.1156L1.25571 15.7434V21.395L1.88356 22.0237V28.931Z"
    />
    <path
      d="M2.21742 10.1992L1.88354 9.66671L3.34565 7.2689L14.5222 0.268833L17.3172 0L17.651 0.532463L13.9255 2.86524L13.0601 2.66665L8.27049 5.66717L8.07104 6.53264L2.21742 10.1992Z"
    />
    <path
      d="M34.1886 10.1992L34.5216 9.66671L33.0595 7.2689L21.883 0.268833L19.088 0L18.7541 0.532463L22.4796 2.86524L23.3451 2.66665L28.1355 5.66717L28.3341 6.53264L34.1886 10.1992Z"
    />
    <path
      d="M34.1886 29.7112L34.5216 30.2436L33.0595 32.6415L21.883 39.6415L19.088 39.9104L18.7541 39.3779L22.4796 37.0451L23.3451 37.2437L28.1355 34.2432L28.3341 33.3777L34.1886 29.7112Z"
    />
    <path
      d="M2.21742 29.7112L1.88354 30.2436L3.34565 32.6415L14.5222 39.6415L17.3172 39.9104L17.651 39.3779L13.9255 37.0451L13.0601 37.2437L8.27049 34.2432L8.07104 33.3777L2.21742 29.7112Z"
    />
    <path
      d="M9.36587 15.7154H15.2195L16.3902 14.5447H21.4633L22.2438 15.3252H26.1462L27.317 16.4959H23.0243L22.2438 17.2764H17.5609L17.1707 16.8861H10.9268L10.5366 17.2764H9.36587L8.58539 16.4959L9.36587 15.7154Z"
    />
    <path
      d="M9.3485 19.813H14.0314C14.0656 19.8131 14.0995 19.8063 14.1309 19.7928C14.1624 19.7794 14.1908 19.7597 14.2144 19.735L15.0027 18.9155C15.0263 18.8907 15.0547 18.8711 15.0861 18.8576C15.1176 18.8442 15.1514 18.8373 15.1856 18.8374H21.9403C21.9746 18.8373 22.0086 18.8441 22.0402 18.8575C22.0718 18.871 22.1004 18.8907 22.1241 18.9155L23.1977 20.0281C23.2218 20.0524 23.2504 20.0717 23.282 20.085C23.3135 20.0982 23.3474 20.1051 23.3816 20.1053H27.0672C27.1344 20.1053 27.1989 20.1319 27.2466 20.1794C27.2942 20.2268 27.3211 20.2912 27.3213 20.3585V21.1207C27.3211 21.188 27.2942 21.2524 27.2466 21.2999C27.1989 21.3473 27.1344 21.374 27.0672 21.374H22.53C22.4956 21.3738 22.4616 21.3807 22.43 21.3941C22.3984 21.4075 22.3699 21.4272 22.3461 21.452L22.1198 21.6862C22.096 21.711 22.0675 21.7307 22.0359 21.7441C22.0043 21.7575 21.9703 21.7643 21.936 21.7642H16.8342C16.7934 21.7643 16.7533 21.7545 16.7171 21.7356L16.0745 21.4026C16.0381 21.3837 15.9976 21.3739 15.9566 21.374H9.3485C9.3143 21.3741 9.28043 21.3672 9.24897 21.3538C9.2175 21.3404 9.18911 21.3207 9.16552 21.2959L8.65647 20.7687C8.61194 20.7219 8.5871 20.6598 8.5871 20.5952C8.5871 20.5306 8.61194 20.4685 8.65647 20.4218L9.16552 19.8945C9.18881 19.8691 9.21707 19.8488 9.24854 19.8348C9.28002 19.8207 9.31404 19.8133 9.3485 19.813Z"
    />
    <path
      d="M19.2085 23.9106H27.3168V25.4716H25.0005L24.6138 25.8619H22.6834L22.2975 25.4716H19.5944L19.2085 25.0814V23.9106Z"
    />
    <path
      d="M16.4213 23.8457H13.6549C13.6144 23.8453 13.5755 23.8298 13.5457 23.8023L13.2855 23.5639C13.2557 23.5363 13.2168 23.5209 13.1762 23.5205H10.7932C10.7526 23.521 10.7137 23.5364 10.6839 23.5639L10.4237 23.8023C10.394 23.8299 10.355 23.8453 10.3145 23.8457H8.7587C8.73501 23.8464 8.71169 23.8518 8.69009 23.8615C8.6685 23.8713 8.64907 23.8853 8.63293 23.9026C8.61678 23.92 8.60426 23.9403 8.59607 23.9626C8.58789 23.9848 8.58421 24.0085 8.58526 24.0322V25.2853C8.58421 25.3089 8.58789 25.3326 8.59607 25.3548C8.60426 25.3771 8.61678 25.3975 8.63293 25.4148C8.64907 25.4322 8.6685 25.4461 8.69009 25.4559C8.71169 25.4657 8.73501 25.471 8.7587 25.4717H16.9971C17.0208 25.471 17.0441 25.4657 17.0657 25.4559C17.0873 25.4461 17.1068 25.4322 17.1229 25.4148C17.139 25.3975 17.1516 25.3771 17.1597 25.3548C17.1679 25.3326 17.1716 25.3089 17.1706 25.2853V24.2958C17.1715 24.2599 17.162 24.2245 17.1434 24.1937C17.1249 24.163 17.0979 24.1382 17.0656 24.1223L16.4863 23.8622C16.4661 23.852 16.4439 23.8464 16.4213 23.8457Z"
    />
  </svg>
);

export default Logo;
